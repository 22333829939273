import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  VStack,
  Box,
  Text,
  useToast,
  // useToast,
} from "@chakra-ui/react";
import { InputField } from "./InputField";
import CustomButton from "./Button";
import React, {  useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import ThankYouPage from "../features/ThankYouPage";
import { Navigate } from "react-router-dom";
//import axios from "axios";

interface ModalProps {
  onOpenModal: any;
  onCloseModal: any;
  isOpenModal: any;
}

export default function ModalComponent({
  isOpenModal,
  onCloseModal,
  onOpenModal,
}: ModalProps) {
  //const toast = useToast();
  const baseUrl = "https://prelaunch.pythonanywhere.com/api/";
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [thankYou, setThankYou] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)



  const Email_Regex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  return (
    <>
    {thankYou ? (
      <Navigate to="/thank you" />
    ) : (
    <Modal isOpen={isOpenModal} onClose={onCloseModal} isCentered={true}>
      <ModalOverlay />
      <ModalContent
        maxWidth={{ base: "93%", md: "68%", lg: 540 }}
        paddingBottom={10}
        marginTop={{ base: "140px", md: "150px", lg: "200px" }}
        //justifyContent={"center"}
      >
        <ModalHeader
          fontFamily={"Exo"}
          textAlign={"center"}
          width={"100%"}
          paddingX={{ base: 4, md: 12, lg: 14 }}
          fontSize={{ base: 14, md: 20, lg: 20 }}
        >
          Are you a fashion designer, tailor, shoemaker or do you sell fashion
          products?
        </ModalHeader>
        <ModalBody>
          <Box
            textAlign={"center"}
            fontFamily={"Exo"}
            paddingBottom={{ base: 4, md: 4, lg: 8 }}
            fontSize={{ base: 12, md: 14, lg: 14 }}
            width={{ base: "100%", md: "100%", lg: "100%" }}
            paddingX={{ base: 0, md: 1, lg: 1 }}
          >
            Sell to millions across the globe from your home with our fashion
            marketplace, making money selling your craft with ease
          </Box>
          <Controller
            control={control}
            rules={{
              required: "email address is required",
              pattern: { value: Email_Regex, message: "Email is invalid" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <VStack
                width={{ base: "100%", md: "100%", lg: "100%" }}
                alignItems={"center"}
                paddingX={{ base: 0, md: "80px", lg: 20 }}
              >
                <InputField
                  placeholder="email address"
                  value={value}
                  onChange={onChange}
                />
                <CustomButton
                  onClick={handleSubmit(() => {
                    setLoading(true)
                    const timeout = setTimeout(() => {
                      // Check if loading time exceeds 10 seconds
                      if (!isSubmitted) {
                        setLoading(false);
                        toast({
                          title: "Network Error",
                          isClosable: true,
                          description:
                            "Please check your network connection and try again.",
                          duration: 3000,
                          status: "error",
                          position: "top",
                        });
                      }
                    }, 12000); 
                    axios
                      .post(`${baseUrl}/seller/save/email`, {
                        email: value,
                        length: 1,
                      })
                      .then((response: any) => {
                        clearTimeout(timeout)
                        setIsSubmitted(true)
                        setLoading(false);
                        setThankYou(true)
                        //setRegister(true);
                        toast({
                          title: "success",
                          isClosable: true,
                          description: "email saved successfully",
                          duration: 3000,
                          status: "success",
                          position: "top",
                        });
                        console.log("'go", value);
                      })
                      .catch((error: any) => {
                        if (error.response) {
                          setLoading(false);
                          console.log("server responded");
                          toast({
                            title: "error",
                            isClosable: true,
                            description: "invalid email or email already exist",
                            duration: 3000,
                            status: "error",
                            position: "top",
                          });
                        }
                      });
                  })}
                  //textAlign={undefined}
                  label={"Join waitlist"}
                  textAlign={"center"}
                  width={{ base: "100%", md: "100%", lg: "100%" }}
                  bgColor="#FFB16C"
                  height={58}
                  borderRadius={4}
                  //textAlign={'center'}
                  paddingTop={4}
                  loading={loading}
                />
                {errors.email?.message && (
                  <Text
                    fontFamily={"Exo"}
                    fontSize={{ base: 10, md: 10, lg: 10 }}
                    textAlign={"center"}
                    color={"#EB5757"}
                    //paddingBottom={10}
                  >
                    Valid email is required.
                  </Text>
                )}
              </VStack>
            )}
            name="email"
          />
        </ModalBody>
      </ModalContent>
    </Modal>
    )}
    </>
  );
}
