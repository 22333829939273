import React from "react";

interface Props {
    width: string | number;
    height: string | number;
}


export default function Facebook({width, height}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#190C01" />
      <g clip-path="url(#clip0_2737_344)">
        <path
          d="M25.7572 10.1442V13.3173H23.8702C23.1811 13.3173 22.7163 13.4615 22.4759 13.75C22.2355 14.0385 22.1153 14.4712 22.1153 15.0481V17.3197H25.637L25.1682 20.8774H22.1153V30H18.4375V20.8774H15.3726V17.3197H18.4375V14.6995C18.4375 13.2091 18.8541 12.0533 19.6875 11.232C20.5208 10.4107 21.6306 10 23.0168 10C24.1947 10 25.1081 10.0481 25.7572 10.1442Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2737_344">
          <rect
            width="10.7692"
            height="20"
            fill="white"
            transform="translate(15 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
