import { Box, Center, Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import Navbar, { ThankYouNav } from "../components/Navbar";
import Instagram from "../assets/Instagram";
import Facebook from "../assets/Facebook";
import Tiktok from "../assets/Titktok";
import Twitter from "../assets/Twitter";
import LinkedIn from "../assets/LinkedIn";

export default function ThankYouPage() {
  return (
    <Box
      paddingLeft={{ base: 3, md: 10, lg: 28 }}
      paddingRight={{ base: 3, md: 10, lg: 28 }}
    >
      <ThankYouNav />
      <Box paddingTop={{ base: 10, md: 20, lg: 20 }}>
        <Text
          textAlign={"center"}
          fontSize={{ base: 20, md: 40, lg: 48 }}
          fontFamily={"Exo"}
          fontWeight={"700"}
        >
          Thank you for signing up
        </Text>
        <Flex justifyContent={"center"}>
          <Text
            textAlign={"center"}
            justifyContent={"center"}
            fontSize={{ base: 12, md: 16, lg: 16 }}
            fontFamily={"Exo"}
            fontWeight={"400"}
            width={{ base: "100%", md: "70%", lg: "45%" }}
          >
            We will keep you up to date with our offerings, launching, special
            offers and promos. You can keep up with us on social media.
          </Text>
        </Flex>

        <Box
          alignItems={"center"}
          display={{ base: "none", md: "flex", lg: "flex" }}
        >
          <HStack
            // alignItems={"center"}
            justifyContent={"space-between"}
            paddingTop={5}
            width={"100%"}
            display={"flex"}
            paddingX={{ md: '220px', lg: '380px'}}
            //maxWidth={"30%"}
            // paddingX={"12px"}
          >
            <a href="https://www.instagram.com/theproattire">
              <Instagram width={40} height={40} />
            </a>
            <a href="/">
              <Facebook width={40} height={40} />
            </a>
            <a href="https://www.tiktok.com/@theproattire">
              <Tiktok width={40} height={40} />
            </a>
            <a href="https://www.twitter.com/theproattire">
              <Twitter width={40} height={40} />
            </a>
            <a href="https://www.linkedin.com/company/proattire/">
              <LinkedIn width={40} height={40} />
            </a>
          </HStack>
        </Box>
        <Box
          alignItems={"center"}
          display={{ base: "flex", md: "none", lg: "none" }}
        >
          <HStack
            // alignItems={"center"}
            justifyContent={"space-between"}
            paddingTop={5}
            // width={"40%"}
            display={"flex"}
            paddingX={"50px"}
            //maxWidth={"30%"}
            // paddingX={"12px"}
          >
            <a href="https://www.instagram.com/theproattire">
              <Instagram width={32} height={32} />
            </a>
            <a href="/">
              <Facebook width={32} height={32} />
            </a>
            <a href="https://www.tiktok.com/@theproattire">
              <Tiktok width={32} height={32} />
            </a>
            <a href="https://www.twitter.com/theproattire">
              <Twitter width={32} height={32} />
            </a>
            <a href="https://www.linkedin.com/company/proattire/">
              <LinkedIn width={32} height={32} />
            </a>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
}
