import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { ClipLoader } from "react-spinners";

interface ButtonProps {
  loading?: boolean;
  onClick: any;
  disable?: boolean;
  label: string;
  width?: string | number | object;
  height?: string | number | object;
  borderWidth?: string | number | object;
  bgColor?: string;
  color?: string;
  borderRadius?: string | number | object;
  padding?: string | number | object;
  paddingLeft?: string | number | object;
  paddingRight?: string | number | object;
  paddingTop?: string | number | object;
  paddingBottom?: string | number | object;
  paddingX?: string | number | object;
  paddingY?: string | number | object;
  fontWeight?: string | number | object;
  fontSize?: string | number | object;
  alignItems?: string | number | object;
  alignSelf?: string | number | object;
  icon?: React.ReactNode;
  flexDirection?: any;
  display?: any;
  justifyContent?: any;
  textAlign: any;
  //borderColor?: string | number | object;
}

export default function CustomButton({
  loading,
  onClick,
  disable,
  label,
  width,
  height,
  borderWidth,
  bgColor,
  color,
  borderRadius,
  padding,
  paddingLeft,
  paddingBottom,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  fontWeight,
  fontSize,
  textAlign,
  alignItems,
  alignSelf,
  icon,
  flexDirection,
  display,
  justifyContent,
  ...props
}: //borderColor,
ButtonProps) {
  return (
    <Box
      width={width}
      height={height}
      borderWidth={borderWidth}
      bgColor={bgColor}
      color={color}
      borderRadius={borderRadius}
      padding={padding}
      paddingLeft={paddingLeft}
      paddingBottom={paddingBottom}
      paddingRight={paddingRight}
      paddingTop={paddingTop}
      onClick={onClick}
      paddingY={paddingY}
      paddingX={paddingX}
      fontWeight={fontWeight}
      fontSize={fontSize}
      flexDirection={flexDirection}
      display={display}
      justifyContent={justifyContent}
      //borderColor={borderColor}
    >
      {loading ? (
        <Box
          alignItems={"center"}
          justifyContent={"center"}
          //paddingX={{base: '140px', md: '240px', lg: '150px'}}
          paddingBottom={"20px"}
          paddingLeft={'45%'}
          alignContent={'center'}
        >
          {" "}
          <ClipLoader />{" "}
        </Box>
      ) : (
        <Text
          textAlign={textAlign}
          style={{ fontFamily: "Exo", fontSize: 16, fontWeight: "400" }}
        >
          {label}
        </Text>
      )}
    </Box>
  );
}
