import React from "react";


interface Props {
    width: string | number;
    height: string | number;
}

export default function LinkedIn({width, height}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#190C01" />
      <path
        d="M13.9583 11.5625C13.4887 11.5625 13.0383 11.7491 12.7062 12.0812C12.3741 12.4133 12.1875 12.8637 12.1875 13.3333C12.1875 13.803 12.3741 14.2534 12.7062 14.5855C13.0383 14.9176 13.4887 15.1042 13.9583 15.1042C14.428 15.1042 14.8784 14.9176 15.2105 14.5855C15.5426 14.2534 15.7292 13.803 15.7292 13.3333C15.7292 12.8637 15.5426 12.4133 15.2105 12.0812C14.8784 11.7491 14.428 11.5625 13.9583 11.5625ZM12.2917 16.5625C12.264 16.5625 12.2375 16.5735 12.218 16.593C12.1985 16.6125 12.1875 16.639 12.1875 16.6667V27.5C12.1875 27.5575 12.2342 27.6042 12.2917 27.6042H15.625C15.6526 27.6042 15.6791 27.5932 15.6987 27.5737C15.7182 27.5541 15.7292 27.5276 15.7292 27.5V16.6667C15.7292 16.639 15.7182 16.6125 15.6987 16.593C15.6791 16.5735 15.6526 16.5625 15.625 16.5625H12.2917ZM17.7083 16.5625C17.6807 16.5625 17.6542 16.5735 17.6347 16.593C17.6151 16.6125 17.6042 16.639 17.6042 16.6667V27.5C17.6042 27.5575 17.6508 27.6042 17.7083 27.6042H21.0417C21.0693 27.6042 21.0958 27.5932 21.1153 27.5737C21.1349 27.5541 21.1458 27.5276 21.1458 27.5V21.6667C21.1458 21.2523 21.3105 20.8548 21.6035 20.5618C21.8965 20.2688 22.2939 20.1042 22.7083 20.1042C23.1227 20.1042 23.5202 20.2688 23.8132 20.5618C24.1062 20.8548 24.2708 21.2523 24.2708 21.6667V27.5C24.2708 27.5575 24.3175 27.6042 24.375 27.6042H27.7083C27.736 27.6042 27.7625 27.5932 27.782 27.5737C27.8015 27.5541 27.8125 27.5276 27.8125 27.5V20.3167C27.8125 18.2942 26.0542 16.7125 24.0417 16.895C23.419 16.9517 22.8091 17.1058 22.2342 17.3517L21.1458 17.8183V16.6667C21.1458 16.639 21.1349 16.6125 21.1153 16.593C21.0958 16.5735 21.0693 16.5625 21.0417 16.5625H17.7083Z"
        fill="white"
      />
    </svg>
  );
}
