import React from "react";
import ProattireLogo from "../assets/ProattireLogo";
import { Box, HStack, Text, useDisclosure } from "@chakra-ui/react";
import ModalComponent from "./ModalComponent";

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      height={70}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      backgroundColor={"transparent"}
    >
      <a href="/">
        <HStack>
          <Box display={{ base: "none", md: "flex", lg: "flex" }}>
            <ProattireLogo width={26} height={26} />
          </Box>
          <Box display={{ base: "flex", md: "none", lg: "none" }}>
            <ProattireLogo width={24} height={24} />
          </Box>
          <Text
            fontSize={{ base: 16, md: 18.32, lg: 18.32 }}
            fontWeight={{ base: "400", md: "400", lg: "700" }}
            fontFamily={"Exo"}
          >
            Proattire
          </Text>
        </HStack>
      </a>
      <HStack>
        <a href="mailto:theproattire@gmail.com">
          <Text
            fontSize={{ base: 16, md: 16, lg: 16 }}
            fontWeight={{ base: "400", md: "400", lg: "400" }}
            fontFamily={"Exo"}
            paddingRight={{ base: 0, md: 0, lg: 5 }}
          >
            Partnership
          </Text>
        </a>
        <Text
          fontSize={{ base: 16, md: 16, lg: 16 }}
          fontWeight={{ base: "400", md: "400", lg: "400" }}
          fontFamily={"Exo"}
          display={{ base: "none", md: "none", lg: "flex" }}
          textDecor={"underline"}
          color={"#267BFB"}
          onClick={onOpen}
        >
          Register as a seller
        </Text>
      </HStack>
      <ModalComponent
        isOpenModal={isOpen}
        onOpenModal={onOpen}
        onCloseModal={onClose}
      />
    </Box>
  );
}

export function ThankYouNav() {
  return (
    <Box
    height={70}
    alignItems={"center"}
    display={"flex"}
    flexDirection={"row"}
    justifyContent={"space-between"}
    backgroundColor={"transparent"}
  >
    <a href="/">
      <HStack>
        <Box display={{ base: "none", md: "flex", lg: "flex" }}>
          <ProattireLogo width={26} height={26} />
        </Box>
        <Box display={{ base: "flex", md: "none", lg: "none" }}>
          <ProattireLogo width={24} height={24} />
        </Box>
        <Text
          fontSize={{ base: 16, md: 18.32, lg: 18.32 }}
          fontWeight={{ base: "400", md: "400", lg: "700" }}
          fontFamily={"Exo"}
        >
          Proattire
        </Text>
      </HStack>
    </a>
    <HStack>
      <a href="mailto:theproattire@gmail.com">
        <Text
          fontSize={{ base: 16, md: 16, lg: 16 }}
          fontWeight={{ base: "400", md: "400", lg: "400" }}
          fontFamily={"Exo"}
          paddingRight={{ base: 0, md: 0, lg: 5 }}
        >
          Partnership
        </Text>
      </a>
    </HStack>
   
  </Box>
  )
}
