import React from "react";

interface Props {
  width: string | number;
  height: string | number;
}

export default function Twitter({ width, height }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#190C01" />
      <path
        d="M16.2825 28.75C23.83 28.75 27.9587 22.4963 27.9587 17.0825C27.9587 16.9075 27.9587 16.73 27.9513 16.555C28.7551 15.9731 29.4488 15.2527 30 14.4275C29.2491 14.7586 28.4535 14.9768 27.6388 15.075C28.4969 14.5621 29.1397 13.755 29.4475 12.8038C28.6412 13.2814 27.7588 13.6167 26.8387 13.795C26.2202 13.1363 25.4018 12.6999 24.5101 12.5535C23.6185 12.407 22.7034 12.5587 21.9067 12.9849C21.1099 13.4112 20.476 14.0883 20.1031 14.9113C19.7301 15.7344 19.639 16.6574 19.8438 17.5375C18.2122 17.4557 16.616 17.0318 15.1588 16.2934C13.7016 15.555 12.4159 14.5186 11.385 13.2513C10.8617 14.1551 10.702 15.2243 10.9383 16.2416C11.1746 17.2589 11.7893 18.1482 12.6575 18.7288C12.0069 18.7067 11.3707 18.532 10.8 18.2188V18.275C10.8011 19.2219 11.1291 20.1393 11.7286 20.8722C12.3281 21.6051 13.1622 22.1086 14.09 22.2975C13.7378 22.3945 13.374 22.4429 13.0087 22.4413C12.7512 22.442 12.4942 22.4182 12.2413 22.37C12.5035 23.1851 13.0141 23.8977 13.7016 24.4081C14.389 24.9185 15.2189 25.2011 16.075 25.2163C14.6207 26.3585 12.8243 26.978 10.975 26.975C10.6492 26.9764 10.3235 26.9576 10 26.9188C11.8769 28.1153 14.0566 28.7507 16.2825 28.75Z"
        fill="white"
      />
    </svg>
  );
}
