import React from "react";

interface Props {
    width: string | number;
    height: string | number;
}


export default function Tiktok({width, height}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#190C01" />
      <g clip-path="url(#clip0_2737_340)">
        <path
          d="M20.4375 10.0167C21.5292 10 22.6125 10.0083 23.6959 10C23.7625 11.275 24.2209 12.575 25.1542 13.475C26.0875 14.4 27.4042 14.825 28.6875 14.9667V18.325C27.4875 18.2833 26.2792 18.0333 25.1875 17.5167C24.7125 17.3 24.2709 17.025 23.8375 16.7417C23.8292 19.175 23.8459 21.6083 23.8209 24.0333C23.7542 25.2 23.3709 26.3583 22.6959 27.3167C21.6042 28.9167 19.7125 29.9583 17.7709 29.9917C16.5792 30.0583 15.3875 29.7333 14.3709 29.1333C12.6875 28.1417 11.5042 26.325 11.3292 24.375C11.3125 23.9583 11.3042 23.5417 11.3209 23.1333C11.4709 21.55 12.2542 20.0333 13.4709 19C14.8542 17.8 16.7875 17.225 18.5959 17.5667C18.6125 18.8 18.5625 20.0333 18.5625 21.2667C17.7375 21 16.7709 21.075 16.0459 21.575C15.5209 21.9167 15.1209 22.4417 14.9125 23.0333C14.7375 23.4583 14.7875 23.925 14.7959 24.375C14.9959 25.7417 16.3125 26.8917 17.7125 26.7667C18.6459 26.7583 19.5375 26.2167 20.0209 25.425C20.1792 25.15 20.3542 24.8667 20.3625 24.5417C20.4459 23.05 20.4125 21.5667 20.4209 20.075C20.4292 16.7167 20.4125 13.3667 20.4375 10.0167Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2737_340">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
