import React from "react";

interface Props {
    width: string | number;
    height: string | number;
}

export default function ProattireLogo({width, height}: Props) {
  return (
    <svg
      width={ width }
      height={ height }
      viewBox="0 0 47 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.247 41.1661C24.7154 43.048 30.7532 38.8138 32.7135 36.4614C17.6584 36.4614 11.2809 32.0181 9.974 29.7964C9.03306 30.11 3.30899 36.2 0.564575 39.2058C3.70105 38.2649 12.3264 40.1206 16.247 41.1661Z"
        fill="#FFB16C"
      />
      <path
        d="M6.44556 20.7792C9.89569 32.0705 24.3496 34.3706 31.1453 34.1092C33.6546 34.1498 34.5431 33.6034 34.6737 33.3251L36.634 29.7965C37.8886 21.0144 26.9632 13.33 21.3437 10.5856L4.09321 0C3.30895 12.5459 5.26923 18.4268 6.44556 20.7792Z"
        fill="#FFB16C"
      />
      <circle cx="44.0832" cy="27.4442" r="2.35236" fill="#FFB16C" />
      <path
        d="M42.907 30.9727C43.5604 33.0636 43.1422 38.0297 36.242 41.1662C31.3539 45.2825 29.4729 47.394 28.9546 48.2365C28.899 48.3653 28.8451 48.4916 28.7928 48.6153C28.7928 48.5449 28.841 48.4212 28.9546 48.2365C31.5405 42.2448 37.6872 30.9727 42.907 30.9727Z"
        fill="#FFB16C"
      />
    </svg>
  );
}

