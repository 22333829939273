import React from "react";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Box,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { ClipLoader } from "react-spinners";

interface CustomInputProps {
  name?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  value?: string | number;
  onChange?: any;
  isRequired?: boolean;
  extraText?: string;
  width?: string | number;
  height?: string | number;
  children?: any;
  isInvalid?: boolean;
  errorMessage?: string;
  ref?: any;
  path?: any;
  onClick?: any;
  isLoading?: boolean;
}

type RequireProperty<T, Prop extends keyof T> = T & { [key in Prop]-?: T[key] };
type RequireInputProps =
  | RequireProperty<CustomInputProps, "label">
  | RequireProperty<CustomInputProps, "placeholder">;

const InputField = ({
  name,
  label,
  placeholder = " ",
  type = "text",
  value,
  onChange,
  isRequired = true,
  extraText = "",
  children,
  width = "100%",
  height = "60px",
  errorMessage,
  isInvalid = false,
  ref,
  path,
  ...props
}: RequireInputProps) => {
  return (
    <Box my={3} width={width} height={height} {...props}>
      <FormControl variant="floating" id={name} isInvalid={isInvalid}>
        <Input
          ref={ref}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          color={"textColor.input"}
          bgColor={"grey.inputBg"}
          height={"60px"}
          borderRadius={4}
          paddingTop={0}
          fontFamily={"Exo"}
        />

        <FormLabel>{label}</FormLabel>

        {extraText && <FormHelperText>{extraText}</FormHelperText>}

        {children}
      </FormControl>
    </Box>
  );
};

interface InputProps {
  name?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  value?: string | number;
  onChange?: any;
  isRequired?: boolean;
  extraText?: string;
  width?: string | number;
  height?: string | number;
  children?: any;
  isInvalid?: boolean;
  errorMessage?: string;
  ref?: any;
  path?: any;
  onClick?: any;
  id?: any;
  disabled?: any;
  isLoading?: boolean;
  validate?: any;
}

export default function SearchBar({
  name,
  label,
  placeholder = " ",
  type = "text",
  value,
  onChange,
  isRequired = true,
  extraText = "",
  children,
  width = "100%",
  height = "62px",
  errorMessage,
  isInvalid = false,
  ref,
  path,
  onClick,
  disabled,
  isLoading,
  id,
  ...props
}: InputProps) {
  return (
    <Box
      width={{ base: "100%", md: "75%", lg: "45%" }}
      paddingLeft={{ base: 0, lg: "none" }}
    >
      <InputGroup>
        <InputLeftElement
          paddingTop={1}
          marginLeft={0}
          h={"full"}
          display={{ base: "none", lg: "flex" }}
        ></InputLeftElement>
        <Input
          id={id}
          ref={ref}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          color={"textColor.input"}
          bgColor={"#F8F8F8"}
          width={{ base: "100%", md: "100%" }}
          height={{ base: "52px", lg: "64px" }}
          borderRadius={8}
          paddingTop={0}
          paddingLeft={{ base: 2, lg: 10 }}
          fontSize={{ base: 14, lg: 16 }}
          fontFamily={"Exo"}
        />
        <InputRightElement
          paddingTop={1}
          paddingRight={7}
          h={"full"}
          width={"25%"}
          display={{ base: "none", lg: "flex" }}
          borderRightRadius={10}
          bgColor={"#FFB16C"}
        >
          {isLoading ? (
            <Box
              alignItems={"center"}
              justifyContent={"center"}
              paddingLeft={"45px"}
              width={"100%"}
            >
              {" "}
              <ClipLoader />{" "}
            </Box>
          ) : (
            <Button
              width={"100%"}
              bgColor={"transparent"}
              fontFamily={"Exo"}
              fontWeight={"400"}
              height={"full"}
              _hover={{
                bgColor: "transparent",
              }}
              onClick={onClick}
              disabled={disabled}
            >
              Notify me
            </Button>
          )}
        </InputRightElement>
        <InputRightElement
          display={{ base: "flex", lg: "none" }}
          height={{ base: "52px", lg: "none" }}
        ></InputRightElement>
      </InputGroup>
    </Box>
  );
}

export { InputField };
