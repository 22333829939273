import React, { useState } from "react";
import CustomButton from "../components/Button";
import SearchBar from "../components/InputField";
import Navbar from "../components/Navbar";
import {
  Box,
  Image,
  Text,
  Flex,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ModalComponent from "../components/ModalComponent";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import ThankYouModal from "../components/ThankYouModal";
import { Navigate } from "react-router-dom";

const BgImage = require("../assets/imgs/Web.png");

export default function Homepage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const baseUrl = "https://prelaunch.pythonanywhere.com/api/";
  const [thankYou, setThankYou] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const Email_Regex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  return (
    <>
      {thankYou ? (
        <Navigate to="/thank you" />
      ) : (
        <Box
          paddingLeft={{ base: 3, md: 10, lg: 28 }}
          paddingRight={{ base: 3, md: 10, lg: 28 }}
        >
          <Navbar />
          <Box
            alignItems={"center"}
            alignContent={"center"}
            width={"100%"}
            paddingTop={{ base: 3, md: 4, lg: 4 }}
            paddingBottom={{ base: 0, md: 20, lg: 0 }}
          >
            <Flex
              width={"100%"}
              alignItems={"center"}
              //paddingLeft={{ base: 0, md: 0, lg: 5 }}
              justify={"center"}
            >
              <Image
                src={BgImage}
                width={{ base: "80%", md: "55%", lg: "55%" }}
                alignContent={"center"}
              />
            </Flex>
            <Box width={"100%"}>
              <Text
                textAlign={{ base: "center", md: "center" }}
                width={{ base: "80%", md: "100%", lg: "100%" }}
                alignItems={"center"}
                fontSize={{ base: 16, md: 20, lg: 28 }}
                fontFamily={"Exo"}
                fontWeight={"700"}
                justifyContent={"center"}
                paddingLeft={{ base: 10, md: 0, lg: 0 }}
              >
                BE THE FIRST TO KNOW WHEN WE LAUNCH
              </Text>
              <Flex
                width={{ base: "100%", md: "100%", lg: "100%" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text
                  fontFamily={"Exo"}
                  fontSize={{ base: 14, md: 16, lg: 16 }}
                  textAlign={"center"}
                  //alignSelf={'center'}
                  width={{ base: "100%", md: "50%", lg: "35%" }}
                  paddingY={{ base: 5, md: 0, lg: 0 }}
                >
                  We're the biggest fashion store in Africa. You can get
                  anything fashion on our platform
                </Text>
              </Flex>
            </Box>

            <Controller
              control={control}
              rules={{
                required: "email address is required",
                pattern: { value: Email_Regex, message: "Email is invalid" },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <Flex
                    justifyContent={"center"}
                    paddingTop={2}
                    //alignSelf={"flex-end"}
                    width={"100%"}
                  >
                    <SearchBar
                      placeholder="Email address"
                      value={value}
                      onChange={onChange}
                      onClick={handleSubmit(() => {
                        setLoading(true);
                        const timeout = setTimeout(() => {
                          // Check if loading time exceeds 10 seconds
                          if (!isSubmitted) {
                            setLoading(false);
                            toast({
                              title: "Network Error",
                              isClosable: true,
                              description:
                                "Please check your network connection and try again.",
                              duration: 3000,
                              status: "error",
                              position: "top",
                            });
                          }
                        }, 12000); // 10 seconds

                        axios
                          .post(`${baseUrl}/buyer/save/email`, {
                            email: value,
                            length: 1,
                          })
                          .then((response: any) => {
                            clearTimeout(timeout);
                            setLoading(false);
                            setThankYou(true);
                            setIsSubmitted(true);
                            //setRegister(true);
                            toast({
                              title: "success",
                              isClosable: true,
                              description: "email saved successfully",
                              duration: 2000,
                              status: "success",
                              position: "top",
                            });
                            console.log("'go", value);
                          })
                          .catch((error: any) => {
                            if (error.response) {
                              setLoading(false);
                              console.log("server responded");
                              toast({
                                title: "error",
                                isClosable: true,
                                description:
                                  "invalid email or email already exist",
                                duration: 5000,
                                status: "error",
                                position: "top",
                              });
                            }
                          });
                      })}
                      id={"email"}
                      isLoading={loading}
                    />
                  </Flex>
                  <Box
                    display={{ base: "flex", md: "flex", lg: "none" }}
                    marginTop={4}
                    justifyContent={"center"}
                    width={"100%"}
                  >
                    <CustomButton
                      onClick={handleSubmit(() => {
                        setLoading(true);
                        const timeout = setTimeout(() => {
                          // Check if loading time exceeds 10 seconds
                          if (!isSubmitted) {
                            setLoading(false);
                            toast({
                              title: "Network Error",
                              isClosable: true,
                              description:
                                "Please check your network connection and try again.",
                              duration: 3000,
                              status: "error",
                              position: "top",
                            });
                          }
                        }, 12000);
                        axios
                          .post(`${baseUrl}/buyer/save/email`, {
                            email: value,
                            length: 1,
                          })
                          .then((response: any) => {
                            clearTimeout(timeout);
                            setLoading(false);
                            setThankYou(true);
                            setIsSubmitted(true);
                            toast({
                              title: "success",
                              isClosable: true,
                              description: "email saved successfully",
                              duration: 3000,
                              status: "success",
                              position: "top",
                            });
                            console.log("'go", value);
                          })
                          .catch((error: any) => {
                            if (error.response) {
                              console.log(error.response);
                              setLoading(false);
                              console.log("server responded");
                              toast({
                                title: "error",
                                isClosable: true,
                                description:
                                  "invalid email or email already exist",
                                duration: 5000,
                                status: "error",
                                position: "top",
                              });
                            }
                          });
                      })}
                      //label={"Notify me"}
                      textAlign={"center"}
                      width={{ base: "100%", md: "75%", lg: "none" }}
                      bgColor="#FFB16C"
                      height={58}
                      borderRadius={4}
                      //textAlign={'center'}
                      paddingTop={4}
                      fontWeight={"400"}
                      label={"Notify me"} //disable={isSubmitting}
                      loading={loading}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    />
                  </Box>
                </>
              )}
              name="email"
            />
            {errors.email?.message && (
              <Text
                fontFamily={"Exo"}
                fontSize={{ base: 10, md: 10, lg: 10 }}
                textAlign={"center"}
                color={"#EB5757"}
              >
                Valid email is required.
              </Text>
            )}

            <Text
              fontSize={{ base: 16, md: 16, lg: 16 }}
              fontWeight={{ base: "400", md: "400", lg: "400" }}
              fontFamily={"Exo"}
              display={{ base: "flex", md: "flex", lg: "none" }}
              textDecor={"underline"}
              color={"#267BFB"}
              justifyContent={"center"}
              paddingTop={{ base: 10, md: 10 }}
              paddingBottom={{ base: 20, md: 10, lg: 0 }}
              onClick={onOpen}
            >
              Register as a seller
            </Text>
          </Box>
          <ModalComponent
            isOpenModal={isOpen}
            onOpenModal={onOpen}
            onCloseModal={onClose}
          />
        </Box>
      )}
    </>
  );
}
