import React from "react";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import Homepage from "./features/Homepage";
import ThankYouPage from "./features/ThankYouPage";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
      <ChakraProvider>
       <Routes>
        <Route path="/" element={ <Homepage /> } />
        <Route path="thank you" element={<ThankYouPage />} /> 
       </Routes>
      </ChakraProvider>
  );
}

export default App;
